import React, { useEffect, useState, useMemo ,useRef} from "react";

import UserService    from '../../services/user.service';

//import './dataTableQuote.css'
import DataTable from "react-data-table-component"
import Select from 'react-select';
import LoadingSpinner from "../loading/loadingComponent";
import {Link} from "react-router-dom";
import { useForm } from 'react-hook-form';
import { getValue } from "@testing-library/user-event/dist/utils";
const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',

};

const DataTableQuote = ({impSearch,supSearch,startDateSearch,endDateSearch,setIDSelected,setEditReq,verCoti,update,setSelectedRows}) => {
    const [quoteData,setQuoteData] = useState({});
    const [prefixContact,setPrefixContact] = useState("");
    let dataMailing = [

        { 
            id: 1,
            type: "Enviar Cotización",
            body :
            Object.keys(quoteData).length !== 0 ? (
            
            "Estimad"+(prefixContact )+  (quoteData.request_parent.request_contact.name + " " + quoteData.request_parent.request_contact.last_name) +"\n \n"+
            
            "Adjunto cotización de transporte Nº"+  quoteData.quote_number  +" de carga "+ (quoteData.request_parent.transport_type ? "exclusiva." : "consolidad.")  +"\n \n"+
            
            "Proveedor " + ( quoteData.request_parent.supplier_selection ? (quoteData.request_parent.contact_supplier ? quoteData.request_parent.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR') : quoteData.request_parent.exporter)  +"\n"+
            "Considerando:\n"+
            "Destino: " + quoteData.request_parent.destination +"\n"+
            "Aduana: "+ quoteData.request_parent.request_custom.custom +" "+quoteData.request_parent.request_custom.city +"\n"+
            "Aguardamos su autorización de embarque\n"
            )
            
            : " "
           
            
        },
        { 
            id: 2,
            type: "Enviar Agradecimiento",
            body :  Object.keys(quoteData).length !== 0 ? (
            
                "Estimad"+(prefixContact )+ (quoteData.request_parent.request_contact.name + " " + quoteData.request_parent.request_contact.last_name) +"  \n\n"+

                "Agradecemos su autorización de embarque de carga "+ (quoteData.request_parent.transport_type ? "exclusiva" : "consolidad") + (quoteData.insurance_status ? " con" : " sin") +" seguro\n\n"+

                "Para ponernos en contacto con su proveedor "+ ( (quoteData.request_parent.contact_supplier ? quoteData.request_parent.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR' ) ) +" por favor proporcionarnos el contacto.\n\n"+

                "Nuestros datos en Brasil son:\n\n"+

                "BBA Transporte Internacional Ltda.\n"+
                "Av. Dr. Alberto Jackson Byington # 3055\n"+
                "Jd Três Montanhas - Cep: 06276-000 Industrial\n"+
                "Anhanguera - Osasco - SP   Modulo “A”\n"+
               
                "Tatiane Novais - Coordenadora Logística\n"+
                "E-mail: logistica@bbatransporte.com\n"+
                "Telf.:  +55 11 94812 3459\n"+
                "Horário de Recebimento de Cargas:\n"+
                "De Segunda a Sexta-Feira\n"+
                "7:00Hrs - 16:30Hrs\n\n"+

                "En cuanto contemos con los documentos finalizados los enviaremos para la emisión de la DAM/DIM.\n"+
                "Favor informarnos el nombre de su agencia despachante y contacto.\n\n"+

                "Estaremos informando.\n"

                )
                
                : " "
        },
        { 
            id: 3,
            type: "Envío de documentos",
            body :  Object.keys(quoteData).length !== 0 ? (
            
                "Estimad"+(prefixContact )+ (quoteData.request_parent.request_contact.name + " " + quoteData.request_parent.request_contact.last_name)+"\n\n"+

                "En adjunto documentos para la elaboración de DAM/DIM.\n\n"+

                "Favor verificar si su mercadería necesita algún tipo de permiso para el cruce de frontera (senasag, agemed, autorizaciones previas, etc), en caso de que requiera dicho permiso prever este documento con anticipación.\n"+
                "Nota: Tomar en cuenta que en el DAM no puede ir declarado “PALLET” como cantidad ni como tipo de bulto, se debe declarar la cantidad y el tipo de bulto que hay dentro de cada pallet.\n"


                )
                
                : " "
        }
    ];
    
    const [mailBody,setMailBody] = useState(' ');
    
    const onChangeSelectMail = (select)  => {
      
        setMailBody(dataMailing.find( (item)=> (item.id == select.target.value) ).body);
       console.log("body: ",dataMailing.find( (item)=> (item.id == select.target.value) ).body);
       console.log("quote",quoteData);
    }
   
    const  onChangeSelectMail2 = (select)  => {
      
        setMailBody(dataMailing.find( (item)=> (item.id == select.value) ).body);
       console.log("body: ",dataMailing.find( (item)=> (item.id == select.value) ).body);
       //console.log("quote",quoteData);
    }
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [updateTable,setUpdateTable] = useState(false);
    
    const [validState,setValidState] = useState("");
    const [validDescription,setValidDescription] = useState("");
    const valStatus = useRef(null);
    const valDescrip = useRef(null);
    const quoteNumberSort = (rowA, rowB) => {
        const a = rowA.quote_number.toLowerCase().substring(0,rowA.quote_number.toLowerCase().length-5);
        const b = rowB.quote_number.toLowerCase().substring(0,rowB.quote_number.toLowerCase().length-5);
        const aInt = parseInt(a);
        const bInt = parseInt(b);
       
       

        const year_a = rowA.quote_number.toLowerCase().substring(rowA.quote_number.toLowerCase().length-4);
        const year_b = rowB.quote_number.toLowerCase().substring(rowB.quote_number.toLowerCase().length-4);
        const yaInt = parseInt(year_a);
        const ybInt = parseInt(year_b);
 
        if(yaInt > ybInt)
        {
            return -1;
        }
        if(ybInt > yaInt  )
        {
            return 1;
        }
        if (aInt > bInt) {
            return -1;
        }
    
        if (bInt > aInt) {
            return 1;
        }
    
        return 0;
    };
    const requestNumberSort = (rowA, rowB) => {
        const a = rowA.request_parent.request_number.toLowerCase().substring(0,rowA.request_parent.request_number.toLowerCase().length-5);
        const b = rowB.request_parent.request_number.toLowerCase().substring(0,rowB.request_parent.request_number.toLowerCase().length-5);
        const aInt = parseInt(a);
        const bInt = parseInt(b);
        if (aInt > bInt) {
            return 1;
        }
    
        if (bInt > aInt) {
            return -1;
        }
    
        return 0;
    };
    const customStyles = {
       
        headCells: {
            style: {
                background :    'navy',
               
                fontWeight: 700,
                fontSize : "2.2cap",
                color : 'white',
                opacity: 0.9
            },
        },
        
    };
   
    async function onSubmit (e) {
        e.preventDefault();
        try{
            //e.preventDefault();

            // Read the form data
            const form = e.target;
            const formData = new FormData(form);
            const formJson = Object.fromEntries(formData.entries());
        
            console.log("1 : ",formJson);
            console.log("1 : ",formJson.validation_status);


            console.log("Quode data: ",quoteData);
        
            const response = await UserService.putQuote(
                quoteData.quote_number,quoteData.quote_date,quoteData.insurance_status,quoteData.insurance_value,quoteData.validity,formJson.validation_status ,
                formJson.validation_description,quoteData.concept1,quoteData.amount1,quoteData.description1,quoteData.concept2,quoteData.amount2,quoteData.description2,
                quoteData.concept3,quoteData.amount3,quoteData.description3,quoteData.concept4,quoteData.amount4,quoteData.description4,quoteData.concept5,quoteData.amount5,
                quoteData.description5,1,quoteData.request_parent.id,quoteData.concept1_status,quoteData.concept2_status,quoteData.concept3_status,
                quoteData.concept4_status,quoteData.concept5_status,quoteData.totalAmount,quoteData.id,
                );
                //.then((response)=>{if(response.data){console.log("Put Respond: ",response.data);setValidDescription(""); e.target.reset();} else{console.log(response.data);}});
            if(response.data)
            {
                console.log("Put Respond: ",response.data);
                //setValidDescription(""); 
                //e.target.reset();
                //const myModal = new bootstrap.Modal(document.getElementById('myModal'), options);
                //myModal.dispose();
                reloadTable();

            }    
            else{
                console.log(response.data);
            }
            //console.log("Quote:",quoteData);
        }catch (error){
            console.error(error);
        }

        
       
       
    }
    const columns1 = [
       
        {
            name: 'CODIGO',
            selector: row => row.quote_number.toUpperCase(),
            sortable: true,
            width: "120px",
            sortFunction: quoteNumberSort
            
        },
        {
            name: 'COD. SOL.',
            selector: row => row.request_parent.request_number.toUpperCase(),
            sortable: true,
            width: "150px",
            sortFunction: requestNumberSort
        },
        {
            name: 'EMPRESA',
            selector: row => row.ent_name,
            sortable: true,
            
            
            
        },
        {
            name: 'PROVEEDOR',
            selector: row => row.request_parent.supplier_selection ? (row.request_parent.contact_supplier ? row.request_parent.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR') : row.request_parent.exporter,
            sortable: true,
           
            
        },
        {
            name: 'PESO',
            selector: row => row.request_parent.weight.toLocaleString("es-BO")+ " Kg",
            sortable: true,
            width: "100px",
            
            
        },
        {
            name: 'VOLUMEN',
            selector: row => row.request_parent.volume.toLocaleString("es-BO")+" m3",
            sortable: true,
            width: "110px",
            
            
        },
        {
            name: 'VALOR',
            selector: row => "$ "+row.request_parent.value.toLocaleString("es-BO"),
            sortable: true,
            width: "100px",
            
            
        },
        {
            name: 'ORIGEN',
            selector: row => row.request_parent.origin.toUpperCase(),
            sortable: true,
            
            
        },
        {
            name: 'DESTINO',
            selector: row => row.request_parent.destination.toUpperCase(),
            sortable: true,
           
            
        },
        {
            button: true,
            width: "60px",
            selector: row => (
                <div >
                    <div className="openbtn text-center">
                        <button
                        type="button"
                        className="btn  btn-sm"
                        data-bs-toggle ="modal"
                        data-bs-target= "#myModal"
                        //data-tag="allowRowEvents"
                        onClick={ () => {setQuoteData(row) ;console.log(row); setValidDescription(row.validation_description);setValidState(row.validation_status)}}
                        >
                            {(() => {
                                        switch (row.validation_status) {
                                        case 'aprobada':
                                            return (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                                </svg>
                                            )
                                        case 'rechazada':
                                            return (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                                </svg>
                                            )
                                        
                                        default:
                                            return (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="navy" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                                     <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                                                </svg>
                                            )
                                        }
                                    })()}
                            
                            
                        </button>
                    </div>
                    
                    <div className='modal modal-lg' tabIndex="-1" id="myModal">
                        <div  className='modal-dialog'>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">ESTADO DE LA COTIZACIÓN</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        
                                    ></button>
                                </div>
                                <div className='modal-body'>
                                    <div className='border rounded border-5 p-2' >   
                                        <form   onSubmit={onSubmit}>
                                                <div className='m-2 input-group mx-auto '>
                                                    
                                                    <div className='input-group-text  '>
                                                    <input 
                                                        type="radio"  
                                                        value= "aprobada"
                                                        name="validation_status"
                                                        checked= {validState === "aprobada"}
                                                        className='form-check-input mt-0'
                                                        onChange={(e) => (setValidState(e.target.value))}
                                                        />
                                                    </div>
                                                    
                                                    <span className='col-md-4 input-group-text'>APROBADA</span>
                                                    <div className='input-group-text  '>
                                                    <input 
                                                        type="radio"  
                                                        value="rechazada"
                                                        name="validation_status"
                                                        checked= {validState === "rechazada"}
                                                        onChange={(e) => (setValidState(e.target.value))}
                                                        className='form-check-input mt-0'
                                                        
                                                        />
                                                    </div>
                                                    <span className='col-md-4 input-group-text'>RECHAZADA</span>
                                                </div>
                                                <div className='m-2 input-group mx-auto'>
                                                
                                                            <span className='col-md-4 input-group-text'>OBSERVACIONES</span>
                                                            <textarea 
                                                                type="text"  
                                                                className='col-md-8 form-control'
                                                                name="validation_description"    
                                                                value={validDescription}
                                                                onChange={(e)=> {setValidDescription(e.target.value)}}
                                                                
                                                        
                                                            />
                                                </div>
                                               
                                                
                                                <div className='m-2 input-group mx-auto'>
                                            
                                                    <button data-bs-dismiss="modal" className="btn btn-lg btn-secondary" style={{backgroundColor: "navy"}}  type="submit">
                                                        CONFIRMAR
                                                    </button>
                                                </div>
                                        </form>        
                                                
                                                
                                                
                                            
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    

                        

                    
                    
                </div>
                
                    )
        },
        {
            button: true,
            width: "60px",
            selector: row => (
                <div >
                    <div className="openbtn text-center">
                        <button
                        type="button"
                        className="btn  btn-sm"
                        data-bs-toggle ="modal"
                        data-bs-target= "#myModalMailing"
                        //data-tag="allowRowEvents"
                        onClick={ () => {setQuoteData(row); setPrefixContact("Sr");}}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="navy" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                            </svg>
                            
                            
                        </button>
                    </div>
                    
                    <div className='modal modal-lg' tabIndex="-1" id="myModalMailing">
                        <div  className='modal-dialog'>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">GENERADOR DE CORREOS</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        
                                    ></button>
                                </div>
                                <div className='modal-body'>
                                    <div className='m-2 input-group mx-auto '>
                                        <span className='col-md-3 input-group-text'>TIPO DE CORREO</span>  
                                        <Select
                                                                                    
                                            className = 'col-md-9'
                                            placeholder="Seleccione el motivo del correo" 
                                            onChange= {(a) => onChangeSelectMail2(a)}
                                            //defaultValue={{ label : condPago[condPagoSelect].name, value: condPago[condPagoSelect].id }}                            
                                            options = {(dataMailing.length !== 0)?  dataMailing.map((option) => ({ label : option.type, value: option.id })) : ([])}
                                            
                                            isSearchable= {false}                           
                                            menuPortalTarget={document.body}     
                                            styles={{menuPortal: base=>({...base,zIndex:9999})}}    
                                                                                        
                                        />   
                                        
                                             
                                    </div>
                                    <div className='m-2 input-group mx-auto '>
                                        <div className='input-group-text col-md-1 '>
                                            <input 
                                                        type="radio"  
                                                        value="o Sr. "
                                                        name="prefix_contact"
                                                        //checked= {prefixContact === "Sr"}
                                                        onChange={(e) => (setPrefixContact(e.target.value))}
                                                        className='form-check-input mt-0'
                                                        
                                            />
                                        </div>
                                        <span className='col-md-5 input-group-text'>Señor</span>
                                        <div className='input-group-text  col-md-1'>
                                            <input 
                                                        type="radio"  
                                                        value="a Sra. "
                                                        name="prefix_contact"
                                                        //checked= {prefixContact === "Sra"}
                                                        onChange={(e) => (setPrefixContact(e.target.value))}
                                                        className='form-check-input mt-0'
                                                        
                                            />
                                        </div>  
                                        <span className='col-md-5 input-group-text'>Señora</span>  
                                    </div>
                                    
                                    <div className='border rounded border-5 p-2' >   
                                    
                                        <div  >
                                            <div className='m-2 input-group mx-auto '>
                                                <span className='col-md-2 input-group-text'>PARA : </span>
                                                <input 
                                                type="text"  
                                                
                                                readOnly
                                                className='col-md-6 form-control'
                                                value={ Object.keys(quoteData).length !== 0   ? (quoteData.request_parent.request_contact.email): ""}
                                          
                                                />
                                                <button className="btn  btn-sm btn-secondary" onClick={ () => {navigator.clipboard.writeText(quoteData.request_parent.request_contact.email + ";" )} }>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
                                                        <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z"/>
                                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z"/>
                                                    </svg>
                                                </button>
                                                
                                            </div>
                                            <div className='m-2 input-group mx-auto '>
                                                <span className='col-md-2 input-group-text'>ASUNTO : </span>
                                                <input 
                                                type="text"  
                                                
                                                readOnly
                                                className='col-md-6 form-control'
                                                value={Object.keys(quoteData).length !== 0   ? ("Cotización N° " +quoteData.quote_number.replace("/","-")+"/"+quoteData.ent_name+"/"+( quoteData.request_parent.supplier_selection ?( quoteData.request_parent.contact_supplier ? quoteData.request_parent.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR'): quoteData.request_parent.exporter )+"/"+
                                                                    quoteData.request_parent.origin+"/"+quoteData.request_parent.destination) : ""}
                                          
                                                />
                                                <button className="btn  btn-sm btn-secondary" onClick={ () => {navigator.clipboard.writeText( "Cotización N° " +quoteData.quote_number.replace("/","-")+"/"+quoteData.ent_name+"/"+(quoteData.request_parent.supplier_selection ? (quoteData.request_parent.contact_supplier ? quoteData.request_parent.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR') : quoteData.request_parent.exporter )+"/"+
                                                                                                                quoteData.request_parent.origin+"/"+quoteData.request_parent.destination )} }>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
                                                        <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z"/>
                                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z"/>
                                                    </svg>
                                                </button>
                                            </div>
                                                <div className='m-2 input-group mx-auto' >
                                                
                                                           
                                                    <textarea 
                                                        type="text"  
                                                        className='col-md-8 form-control'
                                                        //name="validation_description"    
                                                        value={mailBody}
                                                        //onChange={(e)=> {setValidDescription(e.target.value)}}
                                                        style={{height : "300px"}}
                                                        readOnly
                                                    />
                                                </div>
                                               
                                                
                                                <div className='m-2 input-group mx-auto'>
                                            
                                                    <button data-bs-dismiss="modal" className="btn btn-lg btn-secondary" style={{backgroundColor: "navy"}}  type="button"  onClick={() => {navigator.clipboard.writeText(mailBody )}}>
                                                        COPIAR CORREO
                                                    </button>
                                                </div>
                                        </div>        
                                                
                                                
                                                
                                            
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    

                        

                    
                    
                </div>
                
                    )
        },
        {
            button: true,
            width: "60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center ">
                        <button
                        type="button"
                        className="btn  btn-sm "
                        //onClick={() =>{setIDSelected(row); verCoti(true);}}
                        //onClick={setIDSelected(row)}
                        onClick={ () => {verCoti(row)}}
                        >
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#041b5b " class="bi bi-printer" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
                            </svg>
                        </button>
                    </div>
                    
                </div>
                
                    )
        },
       
      
        {
            button: true,
            width: "60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center ">
                        <button
                        type="button"
                        className="btn  btn-sm"
                        
                        onClick={() =>{setIDSelected(row); setEditReq(true);}}
                        //onClick={setIDSelected(row)}
                        //onClick={ () => {setEditReq(true)}}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#041b5b " class="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </div>
                    
                </div>
                
                    )
        },
        {
            button: true,
            width: "60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center ">
                        <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        //onClick={() =>{setIDSelected(row); setEditReq(true);}}
                        //onClick={setIDSelected(row)}
                        onClick={ () => { UserService.deleteQuote(row.id).then( () => {reloadTable()}) }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                            </svg>
                        </button>
                    </div>
                    
                </div>
                
                    )
        }
        
        

       
    ]

    const reloadTable = () => {
        setIsLoading(true);

        UserService.getQuotes().then(quo => {setlistQuotes(quo);  
            
                UserService.getEnterprise().then(ent=>{setlistEnterprises(ent);
                    
                        setIsLoading(false);
                        console.log("reload");
                    
                }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
           
        }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
    }

    const [listEnterprises,setlistEnterprises] = useState([]);
    const [listCities,setlistCities] = useState([]);
    const [listContact,setlistContact] = useState([]);
    const [listEmail,setlistEmail] = useState([]);
    const [listPhones,setlistPhones] = useState([]);
    const [listCitiEnt,setlistCitiEnt] = useState([]);
    const [listAgencies,setlistAgencies] = useState([]);
    const [listRequests,setlistRequests] = useState([]);
    const [listQuotes,setlistQuotes] = useState([]);

    useEffect(() => {
        const getData = () => {
           
        setIsLoading(true);

        UserService.getQuotes().then(quo => {setlistQuotes(quo);  
            
                UserService.getEnterprise().then(ent=>{setlistEnterprises(ent);
                    
                        setIsLoading(false)    
                    
                }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
           
        }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
            
           
           
            
            
            
        };
        
        getData();
    }, []);
   // console.log(listCities);
   //console.log("empresas");
//console.log(listEnterprises);
//console.log("Contactos");
//console.log(listContact);
//console.log("Agencias", listAgencies);
//console.log(listQuotes);

    const enterprisesData = useMemo(() => {
        

        let computedEnterprises = listQuotes.map(client => {
            const idEnt = client;
          
            const otherRecord = listEnterprises.find(({id: candidateid}) => (candidateid===idEnt.request_parent.request_contact.enterprise_id));
           
           
            return otherRecord ? {
                                 ...client,["ent_name"]:otherRecord.name,["ent_id"]:otherRecord.id,["ent_city"]:otherRecord.city_name
                                
                                
                                    } : client;
        });
        
        //Current Page slice
        return computedEnterprises;
    }, [listEnterprises,isLoading,listQuotes]);

    //**************** */
    
    const [filterTextReq, setFilterTextReq] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    let filteredItems = enterprisesData.filter(
        item => ( ((item.ent_name && item.ent_name.toLowerCase().includes(impSearch.toLowerCase())  )
        && ( item.request_parent.supplier_selection ? (item.request_parent.contact_supplier ? item.request_parent.contact_supplier.supplier_name.toLowerCase().includes(supSearch.toLowerCase()) : false) : item.request_parent.exporter.toLowerCase().includes( supSearch.toLowerCase() )
         
        )
        //&& ( (item.request_parent.supplier_selection === true) && (item.request_parent.contact_supplier)  &&(   item.request_parent.contact_supplier.supplier_name).toLowerCase().includes(supSearch.toLowerCase())  )
        //&& ( (item.request_parent.supplier_selection === false ) && (item.request_parent.exporter ) && (item.request_parent.exporter.toLowerCase().includes( supSearch.toLowerCase() ) )  )
        && ( (item.quote_date !== null ) && (item.quote_date >= startDateSearch )) && ( (item.quote_date !== null ) && (item.quote_date <= endDateSearch))
       
        ))
    );
    //console.log("filtros: ",codSearch,reqSearch,startDateSearch,endDateSearch)
    //console.log("Lista",filteredItems,enterprisesData);
    

    
    //console.log("search: ",entSearch);
    //console.log("Search1: ", nameSearch);
    //****************************** */
    //console.log("Search(out usememo):",search);
    
    const handleChange = ({ selectedRows }) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data
        //console.log('Selected Rows: ', selectedRows);
        setSelectedRows(selectedRows);
      };


    return (
        
           

           
            <div className="row w-100 vh-100 ">
                <div className="col mb-3 col-12 text-center">
                {//console.log(isLoading)
                }
                   { isLoading ? <LoadingSpinner/> :
                    <table className="table table-striped">
                              
                                
                                    <DataTable
                                    columns={columns1}
                                    data={filteredItems}
                                    pagination
                                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                    paginationComponentOptions={paginationComponentOptions}
                                    noDataComponent="No hay registros"
                                    persistTableHead
                                    customStyles = {customStyles}
                                    defaultSortFieldId = "1"
                                    highlightOnHover
                                    paginationPerPage={30}
                                    
                                    selectableRows
                                    onSelectedRowsChange={handleChange}
                                    /> 
                                

                                
                                
                               
                    </table>
                    }
                </div>
            </div>
           
        
    );
};

export default DataTableQuote;